/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2022-07-11 18:38:38
 * @LastEditTime: 2023-03-20 14:22:30
 * @FilePath: \community-official-h5\src\config\index.ts
 */
module.exports = {
  /**
   * 本地开发、api请求地址
   */
  host: 'https://mini.wode3000.com', // 正式
  // host: 'http://mini2.wode3000.com', // 测试

  /**
   * @description api请求基础路径
   */
  base_url: {
    dev: '/interApi',
    pro: '/community'
  },

  // 开启开发模式
  isDev: true
}
