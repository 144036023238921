/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-03-20 20:07:51
 * @LastEditTime: 2023-03-20 21:47:20
 * @FilePath: \community-official-h5\src\router\home.routes.ts
 */
/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-03-20 20:07:51
 * @LastEditTime: 2023-03-20 21:44:32
 * @FilePath: \community-official-h5\src\router\home.routes.ts
 */
/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-02-02 11:03:54
 * @LastEditTime: 2023-03-19 18:14:48
 * @FilePath: \community-web\src\router\home.routes.ts
 */
export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/apply-for-operator',
    name: 'apply-for-operator',
    meta: {
      title: '申请运营商'
    },
    component: () => import('@/views/apply-for-operator/apply-for-operator.vue')
  },
  {
    path: '/company-profile',
    name: 'company-profile',
    meta: {
      title: '公司介绍'
    },
    component: () => import('@/views/company-profile/company-profile.vue')
  },
  {
    path: '/join-us',
    name: 'join-us',
    meta: {
      title: '招商加盟'
    },
    component: () => import('@/views/join-us/join-us.vue')
  },

  {
    path: '/join-sanqianli',
    name: 'join-sanqianli',
    meta: {
      title: '加入三千里'
    },
    component: () => import('@/views/join-sanqianli/join-sanqianli.vue')
  },
  {
    path: '/news-consultation',
    name: 'news-consultation',
    meta: {
      title: '新闻咨询'
    },
    component: () => import('@/views/news-consultation/news-consultation.vue')
  },

  {
    path: '/news-defails',
    name: 'news-defails',
    meta: {
      title: '新闻详情'
    },
    component: () => import('@/views/news-defails/news-defails.vue')
  },

  {
    path: '/understand-sanqianli',
    name: 'understand-sanqianli',
    meta: {
      title: '认识三千里'
    },
    component: () => import('@/views/understand-sanqianli/understand-sanqianli.vue')
  }
]
