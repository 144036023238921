/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-02-02 11:03:54
 * @LastEditTime: 2023-02-27 23:25:39
 * @FilePath: \community-h5x\src\router\routers.ts
 */
import { RouteRecordRaw } from 'vue-router'
const routerList:any[] = []

// 自动引入分类路由文件
const importRoutes = (r:any) => {
  r.keys().forEach(
    (key:string) => {
      if (r(key).default instanceof Array) {
        r(key).default.forEach((item:object) => {
          routerList.push(item)
        })
      } else {
        if (Object.keys(r(key).default).length) {
          routerList.push(r(key).default)
        }
      }
    }
  )
}

importRoutes(require.context('./', false, /\.routes\.ts/))

const routes: Array<RouteRecordRaw> = [
  ...routerList,
  {
    path: '/',
    redirect: '/home'
  }
]

export default routes
