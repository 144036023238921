/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2022-07-11 18:31:19
 * @LastEditTime: 2023-03-28 22:27:03
 * @FilePath: \community-official-h5\src\main.ts
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible/flexible.js'
import './style/public.scss'
import Footer from '@/views/footer/footer.vue'

// import('vconsole').then(VConsole => {
// // eslint-disable-next-line no-new, new-cap
//   new VConsole.default()
// })

const app = createApp(App)

app.component('FooterView', Footer)
app.use(Vant)
app.use(store)
app.use(router)
app.mount('#app')
