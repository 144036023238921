/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-03-12 19:15:43
 * @LastEditTime: 2023-03-20 17:34:18
 * @FilePath: \community-official-h5\src\store\modules\user.ts
 */
import common from '@/libs/common'

// 定义用户信息模块下的，state的类型
export type State = {
  userInfo:any;
  viewInfo:object
}

const moduleApp = {
  state: () => ({
    // 用户信息
    userInfo: common.getLocal('setUserInfo') || {},

    // 页面数据缓存
    viewInfo: {}
  }),

  mutations: {
    // 用户信息
    setUserInfo (state:State, data:object) {
      const odlInfo = typeof state.userInfo === 'string' ? JSON.parse(state.userInfo) : state.userInfo

      const userInfo = { ...odlInfo, ...data }

      state.userInfo = userInfo

      common.setLocal('setUserInfo', JSON.stringify(userInfo))
    },

    // 页面缓存数据
    setViewInfo (state:State, data:object) {
      const odlVal:any = state.viewInfo
      for (const key in data) {
        if (key === 'includeName') {
          // keep-alive 缓存页面(如需页面缓存功能，只需把页面名称和路由name对应上就行)
          if (data[key] === 'init-include-list') {
            // 初始化
            odlVal.includeList = []
          } else {
            if (!odlVal.includeList) {
              odlVal.includeList = []
            }
            if (!odlVal.includeList.includes(data[key])) {
              odlVal.includeList.push(data[key])
            }
          }
        } else {
          odlVal[key] = data[key]
        }
      }

      state.viewInfo = odlVal
    },

    // 重置系统信息、退出登录调用
    resetInfo (state:State) {
      state.userInfo = {}
      common.setLocal('setUserInfo', JSON.stringify(state.userInfo))

      state.viewInfo = {}
    }
  },

  getters: {
    // 登录用户信息
    getUserInfo (state:State) {
      const Info = typeof state.userInfo === 'string' ? JSON.parse(state.userInfo) : state.userInfo
      return Info
    }
  },

  actions: {}
}

export default moduleApp
