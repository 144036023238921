/* eslint-disable camelcase */
/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2022-07-11 18:31:19
 * @LastEditTime: 2023-03-20 14:07:33
 * @FilePath: \community-official-h5\src\router\index.ts
 */
import store from '@/store'
import routes from './routers'
import common from '@/libs/common'
import { createRouter, createWebHistory } from 'vue-router'
const config = require('@/config/index')

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// from：前路由
// to：后路由
router.beforeEach(async (to:any, from:any, next) => {
  common.editTitle(to.meta.title)

  if (to.name && !to.meta.cache) {
    // keep-alive:缓存name
    store.commit('setViewInfo', { includeName: to.name })
  }

  if (to.meta.title) {
    const dom:any = document
    dom.title = to.meta.title
  } else {
    document.title = '我的三千'
  }

  // 这里控制是否开启开发模式，不走路由控制
  if (config.isDev) {
    next()
  } else {
    next()
  }
})
export default router
