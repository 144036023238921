import { createStore } from 'vuex'
import User from './modules/user'
export default createStore({
  state: {},

  getters: {},

  mutations: {},

  actions: {},

  modules: {
    User
  }
})
