/*
 * @Author: LYJ1491
 * @Description:
 * @Date: 2023-03-20 13:41:53
 * @LastEditTime: 2023-03-20 22:30:16
 * @FilePath: \community-official-h5\src\libs\common.ts
 */
import dayjs from 'dayjs'

class Common {
  setSession (key: string, data: any) {
    return window.sessionStorage.setItem(key, data)
  }

  getSession (key: string) {
    return window.sessionStorage.getItem(key)
  }

  setLocal (key: string, data: any) {
    return window.localStorage.setItem(key, data)
  }

  getLocal (key: string) {
    return window.localStorage.getItem(key)
  }

  getInitTime (time: any, format: string) {
    if (!time) return
    format = format || 'YYYY-MM-DD HH:mm:ss'
    return time ? dayjs(time).format(format) : dayjs().format(format)
  }

  /**
   *
   * @param {*} data 深拷贝
   */
  deepCopy = (data: any) => {
    const odlVal = JSON.stringify(data)
    const newVal = JSON.parse(odlVal)
    return newVal
  }

  editTitle (title:string) {
    document.title = title
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    // 这个就是我们异步获取 title 的函数
    const fn = () => {
      const times = setTimeout(() => {
        clearTimeout(times)
        iframe.removeEventListener('load', fn)
        document.body.removeChild(iframe)
      }, 0)
    }

    iframe.addEventListener('load', fn)
    document.body.appendChild(iframe)
  }

  isMobileDevice () {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
  }
}

export default new Common()
